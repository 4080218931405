body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

button {
  background-color: transparent;
  border: 0.5px solid rgba(115, 115, 115, 0.608);
  padding: 0px 15px 0px 15px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

button:hover {
  opacity: 0.4;
  transition: 0.2s;
}

.container {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-container {
  width: 85vw;
}

.table-wrap {
  width: 100%;
}

.signin-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.successAlert {
  position: absolute;
  top: 20px;
  right: 10%;
}

.headerBanner {
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(197, 197, 197);
}

.imgBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(197, 197, 197);
}

.header {
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
}

.dashboard-button {
  background-color: transparent;
  color: rgb(58, 58, 58);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 0px 0px 0px;
  margin: 0;
  border: none;
  
  :hover {
    color: gray;
    transition: 0.2s;
  }
}

.signout-btn {
  background-color: transparent;
  border: 0.5px solid rgba(115, 115, 115, 0.608);
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.qpayDownloadBtn {
  background-color: transparent;
  border: 0.5px solid rgba(115, 115, 115, 0.608);
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  margin-bottom: 5px;
  margin-right: 10px;
}

.csvDownloadBtn {
  width: 100%;
}

.markAll {
  color: #f9514e;
  border: 1px solid #f9514e;
  height: fit-content;
  min-height: 40px;
  margin-bottom: 5px;
}

/* Member search */
.member-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CrsidInput {
  width: 100%;
  height: 80%;
  word-wrap: break-word;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  max-width: 700px;
  width: 50%;
  min-height: 200px;
  height: 60%;
  background-color: white;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 1px 1px gray;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* Member page badges */
.badgeContainer {
  position: absolute;
  display: flex;
  align-items: space-between;
}

.badge {
  background-color: coral;
  padding: 0px 5px 0px 5px;
  border-radius: 5px;
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.paid {
  background-color: #ffa1a0;
}

.card {
  background-color: rgb(215, 245, 255);
}

.expire {
  background-color: rgb(199, 199, 199);
}

.valid {
  background-color: rgb(166, 241, 166);
  color: black;
}

/* Amplify */
.data-amplify-container {
  width: 200px;
}